
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userPreferencesSlice, { initialUsersPreferencesState } from './userPreferencesSlice'
import dataSlice, { initialDataState } from './dataSlice'
import formsSlice, { formsInitialState } from './formsSlice'
import tdameritradeSlice, { tdameritradeInitialState } from './tdameritradeSlice'
import liveSlice, { liveInitialState } from './liveSlice'
import supabaseSlice from './supabaseSlice'

export const initialState = {
  userPreferences: initialUsersPreferencesState,
  data: initialDataState,
  forms: formsInitialState,
  tdameritrade: tdameritradeInitialState,
  live: liveInitialState,
  supabase: supabaseSlice
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['market', 'userPreferences', 'supabase']
}

export const rootReducer = combineReducers({
  userPreferences: userPreferencesSlice,
  data: dataSlice,
  forms: formsSlice,
  tdameritrade: tdameritradeSlice,
  live: liveSlice,
  supabase: supabaseSlice
})

export const persistedReducer = persistReducer(persistConfig, rootReducer)

// export store
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
})

// export persistor
// const persistor = persistStore(store)

// configure and export types for hooks
type ConfiguredStore = typeof store
type StoreGetState = ConfiguredStore['getState']
export type RootState = ReturnType<StoreGetState>
export type AppDispatch = ConfiguredStore['dispatch']
