import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ITDAmeritradeUserPrincipals } from '../interfaces/ITDAmeritradeUserPrincipals'
import { ITDAmeritradeStreamQuote } from '../interfaces/ITDAmeritradeStreamQuote'


export interface TDAmeritradeState {
  userPrincipals: ITDAmeritradeUserPrincipals | undefined
  webSocketConnected: boolean
  requestId: number
  latestQuote: ITDAmeritradeStreamQuote | undefined
}

export const tdameritradeInitialState: TDAmeritradeState = {
  userPrincipals: undefined,
  webSocketConnected: false,
  requestId: 0,
  latestQuote: undefined
}

export const tdameritradeSlice = createSlice({
  name: 'tdameritrade',
  initialState: tdameritradeInitialState,
  reducers: {
    setUserPrincipals: (state, action: PayloadAction<ITDAmeritradeUserPrincipals>) => {
      state.userPrincipals = action.payload
    },
    setTDAmeritradeWebSocketConnected: (state, action: PayloadAction<boolean>) => {
      state.webSocketConnected = action.payload
    },
    incrementRequestId: (state) => {
      // increment by two because we always do a quote and an option subscription together (unsubscribe together as well)
      state.requestId += 2
    },
    upsertLatestQuote: (state, action: PayloadAction<ITDAmeritradeStreamQuote>) => {
      state.latestQuote = action.payload
    }
  }
})

export const { setUserPrincipals, setTDAmeritradeWebSocketConnected, incrementRequestId, upsertLatestQuote } = tdameritradeSlice.actions

export default tdameritradeSlice.reducer
