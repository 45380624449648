import { ISessionStats } from "../interfaces/ISessionStats";

export const emptySession: ISessionStats = {
  market: {
    market: "",
    tpoInterval: 0,
    tickSize: 0,
    tickValue: 0,
    ticksPerPoint: 0,
    pointValue: 0,
    type: "",
  },
  sessionId: "",
  formattedDate: "",
  dayOfWeek: "",
  month: "",
  year: 0,
  candles: [],
  overnightCandles: [],
  dailyOTF: "",
  weeklyOTF: "",
  monthlyOTF: "",
  orTrend: "",
  orExtensionUp: "",
  orExtensionDown: "",
  ibTrend: "",
  trTrend: "",
  ibExtensionUp: "",
  ibExtensionUpPeriod: "",
  ibExtensionDown: "",
  ibExtensionDownPeriod: "",
  sessionType: "",
  sessionAnnotation: "",
  openingRange: {
    rangeHigh: 0,
    rangeLow: 0,
    rangeMidpoint: 0,
    rangeSize: 0,
    rangeTicks: 0,
  },
  initialBalance: {
    rangeHigh: 0,
    rangeLow: 0,
    rangeMidpoint: 0,
    rangeSize: 0,
    rangeTicks: 0,
  },
  tradingRange: {
    rangeHigh: 0,
    rangeLow: 0,
    rangeMidpoint: 0,
    rangeSize: 0,
    rangeTicks: 0,
  },
  overnightTradingRange: {
    rangeHigh: 0,
    rangeLow: 0,
    rangeMidpoint: 0,
    rangeSize: 0,
    rangeTicks: 0,
  },
  asiaTradingRange: {
    rangeHigh: 0,
    rangeLow: 0,
    rangeMidpoint: 0,
    rangeSize: 0,
    rangeTicks: 0,
  },
  euTradingRange: {
    rangeHigh: 0,
    rangeLow: 0,
    rangeMidpoint: 0,
    rangeSize: 0,
    rangeTicks: 0,
  },
  globexTotalTradingRange: {
    rangeHigh: 0,
    rangeLow: 0,
    rangeMidpoint: 0,
    rangeSize: 0,
    rangeTicks: 0,
  },
  trToIBRatio: 0,
  vwap: [],
  overnightVWAP: [],
  timeAboveOR: 0,
  timeInOR: 0,
  timeBelowOR: 0,
  timeAboveIB: 0,
  timeInIB: 0,
  timeBelowIB: 0,
  timeAboveTR: 0,
  timeInTR: 0,
  timeBelowTR: 0,
  timeAboveSD1: 0,
  timeWithinSD1: 0,
  timeBelowSD1: 0,
  timeAboveSD2: 0,
  timeWithinSD2: 0,
  timeBelowSD2: 0,
  timeAboveSD3: 0,
  timeWithinSD3: 0,
  timeBelowSD3: 0,
  timeAboveSD4: 0,
  timeWithinSD4: 0,
  timeBelowSD4: 0,
  vwapCrossesUp: 0,
  vwapCrossesDown: 0,
  openWithinPrevRangePercent: 0,
  pointsAbovePrevRangeLow: 0,
  pointsBelowPrevRangeHigh: 0,
  openWRToPrevClosePoints: 0,
  openWRToPrevClosePercent: 0,
  closeInRelationToOR: "",
  closeInRelationToIB: "",
  candleClosesBelowBelowORMidpoint: 0,
  candleClosesAboveAboveORMidpoint: 0,
  candleClosesBelowBelowIBMidpoint: 0,
  candleClosesAboveAboveIBMidpoint: 0,
  crossesORHighPeriodUp: [],
  crossesORHighPeriodDown: [],
  crossesORLowPeriodDown: [],
  crossesORLowPeriodUp: [],
  crossesIBMidpointPeriodUp: [],
  crossesIBMidpointPeriodDown: [],
  crossesIBMidpointPeriodUpCount: 0,
  crossesIBMidpointPeriodDownCount: 0,
  crossesIBHighPeriodUp: [],
  crossesIBHighPeriodDown: [],
  crossesIBHighPeriodDownCount: 0,
  crossesIBHighPeriodUpCount: 0,
  crossesIBLowPeriodDown: [],
  crossesIBLowPeriodUp: [],
  crossesIBLowPeriodUpCount: 0,
  crossesIBLowPeriodDownCount: 0,
  crossesOpenPeriodUp: [],
  crossesOpenPeriodDown: [],
  crossesOpenPeriodUpCount: 0,
  crossesOpenPeriodDownCount: 0,
  openingDrive: "", // either "up", "down", or "none"
  gapName: "",
  gapPercent: 0,
  gapPoints: 0,
  gapFillLevel: 0,
  gapFilled: "",
  gapFillPeriod: "",
  sessionReturn: 0,
  sessionReturnPoints: 0,
  sessionReturnFromGlobex: 0,
  euSessionReturn: 0,
  euSessionTrend: "",
  sessionTrend: "",
  totalVolume: 0,
  totalVolumePercentile: 0,
  ibHighFromOpenPercentChange: 0,
  ibHighFromOpenPointsChange: 0,
  ibLowFromOpenPercentChange: 0,
  ibLowFromOpenPointsChange: 0,
  lowestLevelFromOpenPercentChange: 0,
  lowestLevelFromOpenPointsChange: 0,
  lowestLevelFromIBLowPointsChange: 0,
  lowestLevelFromIBLowPercentChange: 0,
  lowestLevelPeriod: "",
  highestLevelFromOpenPercentChange: 0,
  highestLevelFromOpenPointsChange: 0,
  highestLevelFromIBHighPointsChange: 0,
  highestLevelFromIBHighPercentChange: 0,
  highestLevelPeriod: "",
  highestLevelToLowestLevelRatio: 0,
  trueRange: 0,
  averageTrueRange: 0,
  aPeriodTrend: "",
  aPeriodCandleType: "",
  bPeriodTrend: "",
  bPeriodCandleType: "",
  bPeriodInsideBar: "",
  bPeriodCloseWRToA: "",
  bPeriodRetracePointsIntoA: 0,
  bPeriodRetracePercentIntoA: 0,
  bPeriodHighInRelationToA: "",
  bPeriodLowInRelationToA: "",
  cPeriodTrend: "",
  cPeriodCandleType: "",
  cPeriodInsideBar: "",
  cPeriodCloseWRToB: "",
  cPeriodOpenWRToIB: "",
  cPeriodCloseWRToIB: "",
  cPeriodRetracePointsIntoB: 0,
  cPeriodRetracePercentIntoB: 0,
  cPeriodHighInRelationToB: "",
  cPeriodLowInRelationToB: "",
  dPeriodTrend: "",
  dPeriodCandleType: "",
  dPeriodInsideBar: "",
  dPeriodCloseWRToC: "",
  dPeriodOpenWRToIB: "",
  dPeriodCloseWRToIB: "",
  dPeriodRetracePointsIntoC: 0,
  dPeriodRetracePercentIntoC: 0,
  dPeriodHighInRelationToC: "",
  dPeriodLowInRelationToC: "",
  ePeriodTrend: "",
  ePeriodCandleType: "",
  ePeriodInsideBar: "",
  ePeriodCloseWRToD: "",
  ePeriodOpenWRToIB: "",
  ePeriodCloseWRToIB: "",
  ePeriodRetracePointsIntoD: 0,
  ePeriodRetracePercentIntoD: 0,
  ePeriodHighInRelationToD: "",
  ePeriodLowInRelationToD: "",
  fPeriodTrend: "",
  fPeriodCandleType: "",
  fPeriodInsideBar: "",
  fPeriodCloseWRToE: "",
  fPeriodOpenWRToIB: "",
  fPeriodCloseWRToIB: "",
  fPeriodRetracePointsIntoE: 0,
  fPeriodRetracePercentIntoE: 0,
  fPeriodHighInRelationToE: "",
  fPeriodLowInRelationToE: "",
  gPeriodTrend: "",
  gPeriodCandleType: "",
  gPeriodInsideBar: "",
  gPeriodCloseWRToF: "",
  gPeriodOpenWRToIB: "",
  gPeriodCloseWRToIB: "",
  gPeriodRetracePointsIntoF: 0,
  gPeriodRetracePercentIntoF: 0,
  gPeriodHighInRelationToF: "",
  gPeriodLowInRelationToF: "",
  hPeriodTrend: "",
  hPeriodCandleType: "",
  hPeriodInsideBar: "",
  hPeriodCloseWRToG: "",
  hPeriodOpenWRToIB: "",
  hPeriodCloseWRToIB: "",
  hPeriodRetracePointsIntoG: 0,
  hPeriodRetracePercentIntoG: 0,
  hPeriodHighInRelationToG: "",
  hPeriodLowInRelationToG: "",
  iPeriodTrend: "",
  iPeriodCandleType: "",
  iPeriodInsideBar: "",
  iPeriodCloseWRToH: "",
  iPeriodOpenWRToIB: "",
  iPeriodCloseWRToIB: "",
  iPeriodRetracePointsIntoH: 0,
  iPeriodRetracePercentIntoH: 0,
  iPeriodHighInRelationToH: "",
  iPeriodLowInRelationToH: "",
  jPeriodTrend: "",
  jPeriodCandleType: "",
  jPeriodInsideBar: "",
  jPeriodCloseWRToI: "",
  jPeriodOpenWRToIB: "",
  jPeriodCloseWRToIB: "",
  jPeriodRetracePointsIntoI: 0,
  jPeriodRetracePercentIntoI: 0,
  jPeriodHighInRelationToI: "",
  jPeriodLowInRelationToI: "",
  kPeriodTrend: "",
  kPeriodCandleType: "",
  kPeriodInsideBar: "",
  kPeriodCloseWRToJ: "",
  kPeriodOpenWRToIB: "",
  kPeriodCloseWRToIB: "",
  kPeriodRetracePointsIntoJ: 0,
  kPeriodRetracePercentIntoJ: 0,
  kPeriodHighInRelationToJ: "",
  kPeriodLowInRelationToJ: "",
  lPeriodTrend: "",
  lPeriodCandleType: "",
  lPeriodInsideBar: "",
  lPeriodCloseWRToK: "",
  lPeriodOpenWRToIB: "",
  lPeriodCloseWRToIB: "",
  lPeriodRetracePointsIntoK: 0,
  lPeriodRetracePercentIntoK: 0,
  lPeriodHighInRelationToK: "",
  lPeriodLowInRelationToK: "",
  mPeriodTrend: "",
  mPeriodCandleType: "",
  mPeriodInsideBar: "",
  mPeriodCloseWRToL: "",
  mPeriodOpenWRToIB: "",
  mPeriodCloseWRToIB: "",
  mPeriodRetracePointsIntoL: 0,
  mPeriodRetracePercentIntoL: 0,
  mPeriodHighInRelationToL: "",
  mPeriodLowInRelationToL: "",
  phod: 0,
  plod: 0,
  pvpoc: 0,
  ptpoc: 0,
  pvah: 0,
  pval: 0,
  onh: 0,
  onl: 0,
  prevOpen: 0,
  prevClose: 0,
  mostSimilarSessionByEUReturnFromOpen: [],
  mostSimilarSessionsByReturn: [],
  mostSimilarSessionsByReturnFromOpen: [],
  mostSimilarSessionsByTPOCosine: [],
  mostSimilarSessionsByTPOEuclidean: [],
  mostSimilarSessionsByTPOHamming: [],
  superMatches: [],
  insideDay: "no",
  outsideDay: "no",
  prevSessionInsideDay: "no",
  prevSessionOutsideDay: "no",
  prevCloseVIX: 0,
  tpoMatrix: [[]],
  tpoc: 0,
  tpos: 0,
  tposAtTPOC: 0,
  tposAboveTPOC: 0,
  tpoRowsAboveTPOC: 0,
  tpoRowsAboveOpen: 0,
  tpoRowsAboveClose: 0,
  tposBelowTPOC: 0,
  tpoRowsBelowTPOC: 0,
  tpoRowsBelowOpen: 0,
  tpoRowsBelowClose: 0,
  lvns: [],
  overnightTpoMatrix: [[]],
  overnightTPOC: 0,
  overnightTPOs: 0,
  overnightTPOsAtTPOC: 0,
  overnightTPOsAboveTPOC: 0,
  overnightTpoRowsAboveTPOC: 0,
  overnightTpoRowsAboveOpen: 0,
  overnightTpoRowsAboveClose: 0,
  overnightTPOsBelowTPOC: 0,
  overnightTpoRowsBelowTPOC: 0,
  overnightTpoRowsBelowOpen: 0,
  overnightTpoRowsBelowClose: 0,
  overnightLVNs: [],
  islandReversal: "",
  higherHighPeriods: [],
  lowerLowPeriods: [],
  prevSessionReturn: 0,
  prevSessionType: "",
  prevSessionAnnotation: "",
  prevSessionGapName: "",
  timeAboveOpen: 0,
  timeBelowOpen: 0,
  timeAboveVWAP: 0,
  timeBelowVWAP: 0,
  phodCrossedInOvernightSession: "",
  plodCrossedInOvernightSession: "",
  phodCrossedInRegularSession: "",
  plodCrossedInRegularSession: "",
  onhCrossedInRegularSession: "",
  onlCrossedInRegularSession: "",
  minutesBetweenOpenAndIBHigh: 0,
  minutesBetweenOpenAndIBLow: 0,
  openToPrevClosePointsChange: 0,
  asiaInventoryPercentLong: 0,
  asiaInventoryPercentShort: 0,
  euInventoryPercentLong: 0,
  euInventoryPercentShort: 0,
  onInventoryPercentLong: 0,
  onInventoryPercentShort: 0,
};
