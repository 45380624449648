import * as Sentry from "@sentry/gatsby";

Sentry.init({
  environment: process.env.GATSBY_ACTIVE_ENV,
  dsn: "https://8566d854fba2d68987e2f384e993eec9@o4505623207149568.ingest.sentry.io/4505623209115648",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost:8888", "https://amtjoy.com"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
