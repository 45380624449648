exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-amt-gpt-tsx": () => import("./../../../src/pages/amt-gpt.tsx" /* webpackChunkName: "component---src-pages-amt-gpt-tsx" */),
  "component---src-pages-bayes-lab-tsx": () => import("./../../../src/pages/bayes-lab.tsx" /* webpackChunkName: "component---src-pages-bayes-lab-tsx" */),
  "component---src-pages-candle-lab-tsx": () => import("./../../../src/pages/candle-lab.tsx" /* webpackChunkName: "component---src-pages-candle-lab-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-glossary-tsx": () => import("./../../../src/pages/glossary.tsx" /* webpackChunkName: "component---src-pages-glossary-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-initial-balance-lab-tsx": () => import("./../../../src/pages/initial-balance-lab.tsx" /* webpackChunkName: "component---src-pages-initial-balance-lab-tsx" */),
  "component---src-pages-live-lab-tsx": () => import("./../../../src/pages/live-lab.tsx" /* webpackChunkName: "component---src-pages-live-lab-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-markets-tsx": () => import("./../../../src/pages/markets.tsx" /* webpackChunkName: "component---src-pages-markets-tsx" */),
  "component---src-pages-opening-range-lab-tsx": () => import("./../../../src/pages/opening-range-lab.tsx" /* webpackChunkName: "component---src-pages-opening-range-lab-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-range-lab-tsx": () => import("./../../../src/pages/range-lab.tsx" /* webpackChunkName: "component---src-pages-range-lab-tsx" */),
  "component---src-pages-risk-disclosure-tsx": () => import("./../../../src/pages/risk-disclosure.tsx" /* webpackChunkName: "component---src-pages-risk-disclosure-tsx" */),
  "component---src-pages-risk-lab-tsx": () => import("./../../../src/pages/risk-lab.tsx" /* webpackChunkName: "component---src-pages-risk-lab-tsx" */),
  "component---src-pages-session-lab-tsx": () => import("./../../../src/pages/session-lab.tsx" /* webpackChunkName: "component---src-pages-session-lab-tsx" */),
  "component---src-pages-session-matcher-tsx": () => import("./../../../src/pages/session-matcher.tsx" /* webpackChunkName: "component---src-pages-session-matcher-tsx" */),
  "component---src-pages-session-tree-tsx": () => import("./../../../src/pages/session-tree.tsx" /* webpackChunkName: "component---src-pages-session-tree-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-tpo-lab-tsx": () => import("./../../../src/pages/tpo-lab.tsx" /* webpackChunkName: "component---src-pages-tpo-lab-tsx" */),
  "component---src-pages-upgrade-tsx": () => import("./../../../src/pages/upgrade.tsx" /* webpackChunkName: "component---src-pages-upgrade-tsx" */),
  "component---src-templates-glossary-definition-tsx": () => import("./../../../src/templates/glossary-definition.tsx" /* webpackChunkName: "component---src-templates-glossary-definition-tsx" */),
  "component---src-templates-session-lab-tsx": () => import("./../../../src/templates/session-lab.tsx" /* webpackChunkName: "component---src-templates-session-lab-tsx" */)
}

