import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Constants } from "../constants/Constants";
import { IMarket } from "../interfaces/ISessionStats";
import markets from "../../static/data/markets.json"

export interface UserPreferencesState {
  selectedMarket: IMarket;
  prefersLightMode: boolean;
  primaryColor: string;
  secondaryColor: string;
  upColor: string;
  downColor: string;
  neutralColor: string;
  onhColor: string;
  onlColor: string;
  phodColor: string;
  plodColor: string;
  prevOpenColor: string;
  prevCloseColor: string;
  orColor: string;
  ibColor: string;
  trHighColor: string;
  trLowColor: string;
  openColor: string;
  closeColor: string;
  vwapColor: string;
}

// site looks great on dark mode, so we'll set it as the default
export const initialUsersPreferencesState: UserPreferencesState = {
  selectedMarket: markets[0],
  prefersLightMode: false,
  primaryColor: Constants.AMTJOY_PINK,
  secondaryColor: Constants.AMTJOY_SECONDARY,
  upColor: Constants.AMTJOY_GREEN,
  downColor: Constants.AMTJOY_PINK,
  neutralColor: Constants.NEUTRAL_COLOR,
  onhColor: Constants.ONH_COLOR,
  onlColor: Constants.ONL_COLOR,
  phodColor: Constants.PHOD_COLOR,
  plodColor: Constants.PLOD_COLOR,
  prevOpenColor: Constants.PREV_OPEN_COLOR,
  prevCloseColor: Constants.PREV_CLOSE_COLOR,
  orColor: Constants.OR_COLOR,
  ibColor: Constants.IB_COLOR,
  trHighColor: Constants.TR_HIGH_COLOR,
  trLowColor: Constants.TR_LOW_COLOR,
  openColor: Constants.OPEN_COLOR,
  closeColor: Constants.CLOSE_COLOR,
  vwapColor: Constants.VWAP_COLOR,
};

export const userPreferencesSlice = createSlice({
  name: "userPreferences",
  initialState: initialUsersPreferencesState,
  reducers: {
    setSelectedMarket: (state, action: PayloadAction<IMarket>) => {
      state.selectedMarket = action.payload;
    },
    setPrefersLightMode: (state, action: PayloadAction<boolean>) => {
      state.prefersLightMode = action.payload;
    },
    setPrimaryColor: (state, action: PayloadAction<string>) => {
      state.primaryColor = action.payload;
    },
    setSecondaryColor: (state, action: PayloadAction<string>) => {
      state.secondaryColor = action.payload;
    },
    setUpColor: (state, action: PayloadAction<string>) => {
      state.upColor = action.payload;
    },
    setDownColor: (state, action: PayloadAction<string>) => {
      state.downColor = action.payload;
    },
    setNeutralColor: (state, action: PayloadAction<string>) => {
      state.neutralColor = action.payload;
    },
    setOnhColor: (state, action: PayloadAction<string>) => {
      state.onhColor = action.payload;
    },
    setOnlColor: (state, action: PayloadAction<string>) => {
      state.onlColor = action.payload;
    },
    setPhodColor: (state, action: PayloadAction<string>) => {
      state.phodColor = action.payload;
    },
    setPlodColor: (state, action: PayloadAction<string>) => {
      state.plodColor = action.payload;
    },
    setPrevOpenColor: (state, action: PayloadAction<string>) => {
      state.prevOpenColor = action.payload;
    },
    setPrevCloseColor: (state, action: PayloadAction<string>) => {
      state.prevCloseColor = action.payload;
    },
    setOrColor: (state, action: PayloadAction<string>) => {
      state.orColor = action.payload;
    },
    setIbColor: (state, action: PayloadAction<string>) => {
      state.ibColor = action.payload;
    },
    setTrHighColor: (state, action: PayloadAction<string>) => {
      state.trHighColor = action.payload;
    },
    setTrLowColor: (state, action: PayloadAction<string>) => {
      state.trLowColor = action.payload;
    },
    setOpenColor: (state, action: PayloadAction<string>) => {
      state.openColor = action.payload;
    },
    setCloseColor: (state, action: PayloadAction<string>) => {
      state.closeColor = action.payload;
    },
    setVwapColor: (state, action: PayloadAction<string>) => {
      state.vwapColor = action.payload;
    },
    resetColors: (state) => {
      state.primaryColor = initialUsersPreferencesState.primaryColor;
      state.secondaryColor = initialUsersPreferencesState.secondaryColor;
      state.upColor = initialUsersPreferencesState.upColor;
      state.downColor = initialUsersPreferencesState.downColor;
    },
  },
});

export const {
  setSelectedMarket,
  setPrefersLightMode,
  setPrimaryColor,
  setSecondaryColor,
  setUpColor,
  setDownColor,
  setNeutralColor,
  setOnhColor,
  setOnlColor,
  setPhodColor,
  setPlodColor,
  setPrevOpenColor,
  setPrevCloseColor,
  setOrColor,
  setIbColor,
  setTrHighColor,
  setTrLowColor,
  setOpenColor,
  setCloseColor,
  setVwapColor,
  resetColors,
} = userPreferencesSlice.actions;

export default userPreferencesSlice.reducer;
