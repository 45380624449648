import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "@supabase/supabase-js";

export interface SupabaseState {
  user: User | null;
}

export const supabaseInitialState: SupabaseState = {
  user: null,
};

export const supabaseSlice = createSlice({
  name: "supabase",
  initialState: supabaseInitialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    }
  },
});

export const { setUser } =
  supabaseSlice.actions;

export default supabaseSlice.reducer;
