export class Constants {
  public static readonly OPTIONS_PER_PAGE_API = 100;
  public static readonly GOOGLE_ANALYTICS_COOKIE_NAME =
    "gatsby-gdpr-google-analytics";
  public static readonly ANIMATION_DURATION = 1000;
  public static readonly ANIMATION_DELAY = 600;
  public static readonly LEFT_PAGE = "LEFT";
  public static readonly RIGHT_PAGE = "RIGHT";
  public static readonly PAGINATOR_PAGE_NEIGHBORS = 2;
  public static readonly LOG_OUT_TOAST_WINDOW_ID = "LOG_OUT_TOAST_WINDOW_ID";
  public static readonly LOG_OUT_TOAST_MESSAGE_ID = "LOG_OUT_TOAST_MESSAGE_ID";
  public static readonly DOWNLOAD_START_TOAST_ID = "DOWNLOAD_START_TOAST_ID";
  public static readonly DOWNLOAD_FINISHED_TOAST_ID =
    "DOWNLOAD_FINISHED_TOAST_ID";
  public static readonly STAGING_WARNING_TOAST_ID = "STAGING_WARNING_TOAST_ID";
  public static readonly RETRIEVING_CONTRACTS_ERROR_TOAST_ID =
    "RETRIEVING_CONTRACTS_ERROR_TOAST_ID";
  public static readonly FAILED_TO_FETCH_TOAST_ID =
    "RETRIEVING_CONTRACTS_ERROR_TOAST_ID";
  public static readonly FORMS_MESSAGE_MIN_LENGTH = 50;
  public static readonly FORMS_MESSAGE_MAX_LENGTH = 500;
  public static readonly INDEXED_DB_SCHEMA_VERSION = 4;
  public static readonly PRICE_HUB_URL = "/priceHub";
  public static readonly PRICES_UPDATED_EVENT_ID = "PricesUpdated";
  public static readonly MAX_WEBSOCKET_RECONNECT_RETRIES = 10;
  public static readonly PAYPAL_CLIENT_ID =
    "Aab6DpWxprQhNQwg3shtAc3-C9L7XNRjDkPDdFgon-OUStvgytn16lJYVE5PzgDUTWSC52kF86Oon5IZ";
  public static readonly PAYPAL_PREMIUM_PLAN_ID = "P-7U337233U9062634UMBFUIKQ";
  public static readonly PAYPAL_PLATINUM_PLAN_ID = "P-6UD57375W06957413MP62U5Y";
  public static readonly MARKET_OPEN_STRING = "The market is open.";
  public static readonly MARKET_CLOSED_STRING = "The market is closed.";
  public static readonly MARKET_TIMEZONE = "America/New_York";
  public static readonly MARKET_LOCALE = "en-US";
  public static readonly MEDIUM_LINK = "https://medium.com/amt-joy";
  public static readonly SUBSTACK_LINK = "https://amtjoy.substack.com/";
  public static readonly TWITTER_LINK = "https://twitter.com/BadESTrader";
  public static readonly SEARCH_PARAM_KEYS = {
    TICKER: "ticker",
  };
  public static readonly GOOGLE_TAG = "G-4D5R54PWXE";
  public static readonly PRODUCT_NAMES = {
    AMT_JOY: "AMT JOY",
  };

  // ensure that these line up with the sass variables in _presets.scss
  public static readonly COLORS = {
    LIGHT: "#ffffff",
    DARK: "#232323",
    PRIMARY: "#0056b3",
    GREEN: "#59e59c",
    RED: "#ff6d63",
    GOLD: "#FFD501",
    PURPLE: "#8e44ad",
    PINK: "#f1256e",
    CYAN: "#25ddf1",
  };

  public static readonly TIME_TO_PERIOD_MAP = {
    "9:30 AM": "A",
    "10:00 AM": "B",
    "10:30 AM": "C",
    "11:00 AM": "D",
    "11:30 AM": "E",
    "12:00 PM": "F",
    "12:30 PM": "G",
    "1:00 PM": "H",
    "1:30 PM": "I",
    "2:00 PM": "J",
    "2:30 PM": "K",
    "3:00 PM": "L",
    "3:30 PM": "M",
  };

  // EST candle times for overnight session
  public static readonly OVERNIGHT_TIME_TO_PERIOD_MAP_OLD = {
    "4:00 PM": "a",
    "4:30 PM": "b",
    // --- globex closed for 1 hour ---
    "6:00 PM": "c",
    "6:30 PM": "d",
    "7:00 PM": "e",
    "7:30 PM": "f",
    "8:00 PM": "g",
    "8:30 PM": "h",
    "9:00 PM": "i",
    "9:30 PM": "j",
    "10:00 PM": "k",
    "10:30 PM": "l",
    "11:00 PM": "m",
    "11:30 PM": "n",
    "12:00 AM": "o",
    "12:30 AM": "p",
    "1:00 AM": "q",
    "1:30 AM": "r",
    "2:00 AM": "s",
    "2:30 AM": "t",
    "3:00 AM": "u",
    "3:30 AM": "v",
    "4:00 AM": "w",
    "4:30 AM": "x",
    "5:00 AM": "y",
    "5:30 AM": "z",
    "6:00 AM": "{",
    "6:30 AM": "|",
    "7:00 AM": "}",
    "7:30 AM": "~",
    "8:00 AM": "=",
    "8:30 AM": "-",
    "9:00 AM": ">",
  };

  public static readonly OVERNIGHT_TIME_TO_PERIOD_MAP = {
    "6:00 PM": "a",
    "6:30 PM": "b",
    "7:00 PM": "c",
    "7:30 PM": "d",
    "8:00 PM": "e",
    "8:30 PM": "f",
    "9:00 PM": "g",
    "9:30 PM": "h",
    "10:00 PM": "i",
    "10:30 PM": "j",
    "11:00 PM": "k",
    "11:30 PM": "l",
    "12:00 AM": "m",
    "12:30 AM": "n",
    "1:00 AM": "o",
    "1:30 AM": "p",
    "2:00 AM": "q",
    "2:30 AM": "r",
    "3:00 AM": "s",
    "3:30 AM": "t",
    "4:00 AM": "u",
    "4:30 AM": "v",
    "5:00 AM": "w",
    "5:30 AM": "x",
    "6:00 AM": "y",
    "6:30 AM": "z",
    "7:00 AM": "{",
    "7:30 AM": "|",
    "8:00 AM": "}",
    "8:30 AM": "~",
    "9:00 AM": "=",
  };

  public static readonly PERIOD_CLOSE_MAP_MILITARY = {
    "9:30": { period: "premarket", index: 0 },
    "10:00": { period: "A", index: 0 },
    "10:30": { period: "B", index: 1 },
    "11:00": { period: "C", index: 2 },
    "11:30": { period: "D", index: 3 },
    "12:00": { period: "E", index: 4 },
    "12:30": { period: "F", index: 5 },
    "13:00": { period: "G", index: 6 },
    "13:30": { period: "H", index: 7 },
    "14:00": { period: "I", index: 8 },
    "14:30": { period: "J", index: 9 },
    "15:00": { period: "K", index: 10 },
    "15:30": { period: "L", index: 11 },
    "16:00": { period: "M", index: 12 },
  };

  public static readonly PERIOD_INDEX_TO_LETTER_MAP = {
    0: "A",
    1: "B",
    2: "C",
    3: "D",
    4: "E",
    5: "F",
    6: "G",
    7: "H",
    8: "I",
    9: "J",
    10: "K",
    11: "L",
    12: "M",
  };
  public static readonly PERIOD_TO_INDEX_MAP = {
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    E: 4,
    F: 5,
    G: 6,
    H: 7,
    I: 8,
    J: 9,
    K: 10,
    L: 11,
    M: 12,
  };
    
  public static readonly PERIODS = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
  ];

  public static readonly OVERNIGHT_PERIOD_INDEX_TO_LETTER_MAP = {
    0: "a",
    1: "b",
    2: "c",
    3: "d",
    4: "e",
    5: "f",
    6: "g",
    7: "h",
    8: "i",
    9: "j",
    10: "k",
    11: "l",
    12: "m",
    13: "n",
    14: "o",
    15: "p",
    16: "q",
    17: "r",
    18: "s",
    19: "t",
    20: "u",
    21: "v",
    22: "w",
    23: "x",
    24: "y",
    25: "z",
    26: "|",
    27: "{",
    28: "}",
    29: "~",
    30: "=",
  };
  public static readonly OVERNIGHT_PERIODS = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "|",
    "{",
    "}",
    "~",
    "=",
  ];

  public static readonly AMTJOY_PINK = "#FF4CB2";
  public static readonly AMTJOY_GREEN = "#58E59C";
  public static readonly AMTJOY_SECONDARY = "#2E2E31";
  public static readonly NEUTRAL_COLOR = "#ffc106";
  public static readonly ONL_COLOR = "#0000FF";
  public static readonly ONH_COLOR = "#0000FF";
  public static readonly PHOD_COLOR = "#FFFF00";
  public static readonly PLOD_COLOR = "#FFFF00";
  public static readonly PREV_OPEN_COLOR = "#ebff9b";
  public static readonly PREV_CLOSE_COLOR = "#ffcccc";
  public static readonly OPEN_COLOR = "#ceff9b";
  public static readonly CLOSE_COLOR = "#ff9b9b";
  public static readonly OR_COLOR = "#02f2ff";
  public static readonly IB_COLOR = "#FFA502";
  public static readonly TR_HIGH_COLOR = "#0CFC02";
  public static readonly TR_LOW_COLOR = "#FF1600";
  public static readonly VWAP_COLOR = "#c300ff";
}
