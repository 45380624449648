import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICandle, ISessionStats } from "../interfaces/ISessionStats";
import { emptySession } from "../fixtures/emptySession";

export interface LiveState {
  liveSession: ISessionStats;
  currentBar: ICandle;
  totalVolumeSoFar: number;
  barVolumeSoFar: number;
  notificationsActive: boolean;
  speechActive: boolean;
}

export const liveInitialState: LiveState = {
  liveSession: emptySession,
  currentBar: {
    t: 0,
    o: 0,
    h: 0,
    l: 0,
    c: 0,
    v: 0,
    r: 0,
    rp: 0,
    rtrr: 0,
    rtrrp: 0,
    twp: 0,
    twpr: 0,
    bwp: 0,
    bwpr: 0,
    rfoo: 0,
    rfoh: 0,
    rfol: 0,
    rfoc: 0,
    rfop: 0,
    rfg: 0,
    p: 0,
    pr: 0,
    perr: 0,
  },
  totalVolumeSoFar: 0,
  barVolumeSoFar: 0,
  notificationsActive: true,
  speechActive: true,
};

export const liveSlice = createSlice({
  name: "live",
  initialState: liveInitialState,
  reducers: {
    setLiveSession: (state, action: PayloadAction<ISessionStats>) => {
      state.liveSession = action.payload;
    },
    setLiveSessionOvernightCandles: (
      state,
      action: PayloadAction<ICandle[]>
    ) => {
      state.liveSession.overnightCandles = action.payload;

      // can also set the overnight high and low (so far)
      let overnightHigh = 0;
      let overnightLow = Infinity;
      action.payload.forEach((candle) => {
        if (candle.h > overnightHigh) {
          overnightHigh = candle.h;
        }
        if (candle.l < overnightLow) {
          overnightLow = candle.l;
        }
      });
      state.liveSession.onh = overnightHigh;
      state.liveSession.onl = overnightLow;
    },
    setLiveSessionCandles: (state, action: PayloadAction<ICandle[]>) => {
      state.liveSession.candles = action.payload;

      // can also update the trading range (so far)
      // let tradingRangeHigh = 0;
      // let tradingRangeLow = Infinity;
      // action.payload.forEach((candle) => {
      //   if (candle.h > tradingRangeHigh) {
      //     tradingRangeHigh = candle.h;
      //   }
      //   if (candle.l < tradingRangeLow) {
      //     tradingRangeLow = candle.l;
      //   }
      // }
      // );
      // state.liveSession.tradingRange.rangeHigh = tradingRangeHigh;
      // state.liveSession.tradingRange.rangeLow = tradingRangeLow;
      // state.liveSession.tradingRange.rangeMidpoint = (tradingRangeHigh + tradingRangeLow) / 2;
      // state.liveSession.tradingRange.rangeSize = tradingRangeHigh - tradingRangeLow;
      // state.liveSession.tradingRange.rangeTicks = tradingRangeHigh - tradingRangeLow / state.liveSession.market.tickSize;
    },
    setTotalVolumeSoFar: (state, action: PayloadAction<number>) => {
      state.totalVolumeSoFar = action.payload;
    },
    setBarVolumeSoFar: (state, action: PayloadAction<number>) => {
      state.barVolumeSoFar = action.payload;
    },
    setCurrentBar: (state, action: PayloadAction<ICandle>) => {
      state.currentBar = action.payload;
    },
    updateLatestBarInOvernightCandles: (
      state,
      action: PayloadAction<ICandle>
    ) => {
      const latestBar = action.payload;
      const updatedOvernightCandles = state.liveSession.overnightCandles.map(
        (candle) => {
          if (candle.t === latestBar.t) {
            return latestBar;
          }
          return candle;
        }
      );
      state.liveSession.overnightCandles = updatedOvernightCandles;
    },
    updateLatestBarInCandles: (state, action: PayloadAction<ICandle>) => {
      const latestBar = action.payload;
      const updatedCandles = state.liveSession.candles.map((candle) => {
        if (candle.t === latestBar.t) {
          return latestBar;
        }
        return candle;
      });
      state.liveSession.candles = updatedCandles;
    },
    setNotificationsActive: (state, action: PayloadAction<boolean>) => {
      state.notificationsActive = action.payload;
    },
    setSpeechActive: (state, action: PayloadAction<boolean>) => {
      state.speechActive = action.payload;
    }
  },
});

export const {
  setLiveSession,
  setLiveSessionCandles,
  setLiveSessionOvernightCandles,
  setTotalVolumeSoFar,
  setBarVolumeSoFar,
  setCurrentBar,
  updateLatestBarInOvernightCandles,
  updateLatestBarInCandles,
  setNotificationsActive,
  setSpeechActive
} = liveSlice.actions;

export default liveSlice.reducer;
