import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ISessionStats } from '../interfaces/ISessionStats'
import { ICandleStats } from '../interfaces/ICandleStats';

export interface ICandleMap {
  selectedMarket: string;
  interval: string;
  candleData: ICandleStats;
}

export interface DataState {
  sessionData: Array<ISessionStats>
  allCandleData: Array<ICandleMap>
  periodCandleData: Array<ICandleMap>
  vixCandleData: Array<ICandleMap>
}

export const initialDataState: DataState = {
  sessionData: [],
  allCandleData : [],
  periodCandleData: [],
  vixCandleData: []
}

export const dataSlice = createSlice({
  name: 'data',
  initialState: initialDataState,
  reducers: {
    setSessionData: (state, action: PayloadAction<Array<ISessionStats>>) => {
      state.sessionData = action.payload
    },
    upsertCandleData: (state, action: PayloadAction<ICandleMap>) => {
      // finds if the candleData with the given market and interval already exists
      const candleData = state.allCandleData.find((candle) => {
        return candle.selectedMarket === action.payload.selectedMarket && candle.interval === action.payload.interval
      })
      // if it exists, update the candleData
      if (candleData) {
        candleData.candleData = action.payload.candleData
      } else {
        // if it doesn't exist, add the candleData
        state.allCandleData.push(action.payload)
      }
    },
    upsertPeriodCandleData: (state, action: PayloadAction<ICandleMap>) => {
      // finds if the candleData with the given market and interval already exists
      const candleData = state.periodCandleData.find((candle) => {
        return candle.selectedMarket === action.payload.selectedMarket && candle.interval === action.payload.interval
      })
      // if it exists, update the candleData
      if (candleData) {
        candleData.candleData = action.payload.candleData
      } else {
        // if it doesn't exist, add the candleData
        state.periodCandleData.push(action.payload)
      }
    },
    upsertVixCandleData: (state, action: PayloadAction<ICandleMap>) => {
      // finds if the candleData with the given market and interval already exists
      const candleData = state.vixCandleData.find((candle) => {
        return candle.selectedMarket === action.payload.selectedMarket && candle.interval === action.payload.interval
      })
      // if it exists, update the candleData
      if (candleData) {
        candleData.candleData = action.payload.candleData
      } else {
        // if it doesn't exist, add the candleData
        state.vixCandleData.push(action.payload)
      }
    }
  }
})

export const { setSessionData, upsertCandleData, upsertPeriodCandleData, upsertVixCandleData } = dataSlice.actions

export default dataSlice.reducer
